<template>
  <div class="record with-container">
    <div class="title">魔筷知识产权投诉列表</div>
    <div class="main">
      <el-tabs v-model="status" type="card" @tab-click="handleTabClick">
        <el-tab-pane 
          v-for="item in tabs" 
          :key="item.value" 
          :label="item.label" 
          :name="item.value.toString()"
        ></el-tab-pane>
      </el-tabs>

      <el-table 
        :data="tableData"
        header-cell-class-name="header-cell"
      >
        <el-table-column
          label="投诉时间"
        >
          <template slot-scope="scope">
            <span class="column">{{ scope.row.complaint_time }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="投诉类型"
        >
          <template slot-scope="scope">
            <span class="column">{{ complaintTypes[scope.row.complaint_type] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="投诉来源"
        >
          <template slot-scope="scope">
            <span class="column">{{ subjects[scope.row.subject] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
        >
          <template slot-scope="scope">
            <span :class="`column ${statusTexts[scope.row.status].class}`">{{ statusTexts[scope.row.status].text }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
        >
          <template slot-scope="scope">
            <span class="item-detail" @click="handleDetailClick(scope.row.id)">查看投诉信息</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="totalCount"
          @current-change="onCurrentPageChange"
        ></el-pagination>
      </div>
    </div>

    <complaint-modal
      title="查看投诉信息"
      :visible="detailVisible"
      :contentWithPadding="false"
      :showCancelBtn="detail.status === 1 || detail.status === 3"
      confirmText="我知道了"
      cancelText="补充证据"
      @confirm="onDetailConfirm"
      @cancel="onDetailCancel"
      @close="closeDetailModal"
    >
      <div v-if="detail.status === 3 || detail.status === 4" class="reason">
        <img class="refuse-icon" src="https://mktv-in-cdn.mockuai.com/16191573237978656.png" alt="">
        <span class="text"></span> {{refuseReason}}
      </div>
      <div 
        v-for="item in detailData"
        :key="item.key"
        class="info-item"
      >
        <div class="label">{{`${item.label}:`}}</div>
        
        <div 
          v-if="item.show === 'image'"
          class="imgs"
        >
          <viewer :images="item.value">
            <template slot-scope="scope">
              <img v-for="(src, i) in scope.images" :key="i" class="img-item" :src="src" alt="">
            </template>
          </viewer>
          <!-- <div
            v-for="(img, i) in item.value"
            :key="i"
            class="img-item"
            :style="`background-image: url(${img});`"
          ></div> -->
        </div>
        <div v-else class="val">
          {{item.value}}
          <span v-if="item.key === 'status' && detail.status === 3">(剩余{{countdownText}}关闭)</span>
        </div>
      </div>
    </complaint-modal>

    <complaint-modal
      title="补充证据"
      :visible="supplyVisible"
      confirmText="确认上传"
      @confirm="supplyEvidence"
      @cancel="closeSupplyModal"
      @close="closeSupplyModal"
    >
      <div class="type-info">
        <div class="label">投诉类型:</div>
        <div class="text">{{complaintTypes[detail.complaint_type]}}</div>
      </div>
      <file-upload 
        multiple
        @success="onEviImgChange" 
        @error="onFileUploadError"
        @delete="onEviImgDelete"
      ></file-upload>
    </complaint-modal>
  </div>
</template>

<script>
import ComplaintModal from '@/components/ComplaintModal.vue'
import FileUpload from '@/components/FileUpload.vue'
import util from '@/js/util';

const complaintTypes = {
  1: '商标权',
  2: '著作权',
  3: '专利权',
  4: '依法违规投诉'
}

const subjects = {
  0: '个人',
  1: '单位'
}

const statusTexts = {
  1: {
    text: '已提交待受理',
    class: 'handle'
  },
  2: {
    text: '已受理',
    class: 'success'
  },
  3: {
    text: '已驳回待处理',
    class: 'fail'
  },
  4: {
    text: '超时已失效',
    class: 'handle'
  }
}

const detailLabels = {
  gmt_created: {
    label: '投诉时间',
    order: 1,
    show: 'text'
  },
  complaint_type: {
    label: '投诉类型',
    order: 2,
    show: 'text'
  },
  status: {
    label: '投诉状态',
    order: 3,
    show: 'text'
  },
  item_registration_certificate: {
    label: '商标注册证',
    order: 4,
    show: 'image'
  },
  item_authorization: {
    label: '商品授权书',
    order: 5,
    show: 'image'
  },
  trademark_transfer_certificate: {
    label: '商品转让证明',
    order: 6,
    show: 'image'
  },
  work_registration_certificate: {
    label: '作品登记证书',
    order: 7,
    show: 'image'
  },
  item_original_picture: {
    label: '商品原图',
    order: 8,
    show: 'image'
  },
  item_sidelights: {
    label: '商品花絮图',
    order: 9,
    show: 'image'
  },
  patent_certificate: {
    label: '专利证书',
    order: 10,
    show: 'image'
  },
  patent_specification: {
    label: '专利权利要求与说明书',
    order: 11,
    show: 'image'
  },
  patent_payment_certificate: {
    label: '专利缴费证明',
    order: 12,
    show: 'image'
  },
  business_license_certificate: {
    label: '营业执照证明',
    order: 13,
    show: 'image'
  },
  
  subject: {
    label: '投诉主体',
    order: 15,
    show: 'text'
  },
  business_name: {
    label: '企业名称',
    order: 16,
    show: 'text'
  },
  contacts_name: {
    label: '联系人姓名',
    order: 17,
    show: 'text'
  },
  contacts_mobile: {
    label: '联系人电话',
    order: 18,
    show: 'text'
  },
  email: {
    label: '邮箱',
    order: 19,
    show: 'text'
  },
  address: {
    label: '地址',
    order: 20,
    show: 'text'
  },
  id_card_front: {
    label: '身份证',
    order: 21,
    show: 'image'
  },
  id_card_reverse: {
    label: '身份证',
    order: 22,
    show: 'image'
  },
  evidence_url_list: {
    label: '补充证据',
    order: 23,
    show: 'image'
  },
}

export default {
  name: 'IntellectualRecord',
  components: {
    ComplaintModal,
    FileUpload,
  },
  data() {
    return {
      status: 'all',
      totalCount: 0,
      currentPage: 1,
      pageSize: 20,
      tabs: [
        {
          value: 'all',
          label: '全部'
        },
        {
          value: '1',
          label: '已提交待受理'
        },
        {
          value: '3',
          label: '已驳回待处理'
        },
        {
          value: '4',
          label: '超时已失效'
        }
      ],
      complaintTypes,
      subjects,
      statusTexts,
      tableData: [],
      detail: {},
      detailVisible: false,
      supplyVisible: false,
      evidenceUrlList: [],
      refuseReason: '',
      timeDiff: 0,
      timer: null
    }
  },

  computed: {
    countdownText() {
      return util.formatTimeDiff(this.timeDiff)
    },

    listParams() {
      const base = { offset: (this.currentPage - 1) * this.pageSize, count: this.pageSize };
      return this.status === 'all' ? base : { status: this.status, ...base };
    },

    detailData() {
      let filterKeys;
      let data
      let newData = []
      try {
        filterKeys = ['user_id', 'id', 'area', 'city', 'province', 'detail', 'expired_time', 'refuse_reason']
        data =  Object.keys(this.detail).filter(key => !filterKeys.includes(key)).map(key => ({
          key,
          value: this.handleDetail(key),
          label: detailLabels[key].label,
          order: detailLabels[key].order,
          show: detailLabels[key].show
        }))

        newData = [];
        data.forEach(item => {
          let ele = newData.find(e => e.label === item.label)
          if (ele) {
            ele.value = Array.isArray(ele.value) ? ele.value.concat([item.value]) : [ele.value].concat([item.value]);
          } else if (item.value || item.value.length){
            if (item.show === 'image') {
              if (typeof item.value === 'string') {
                newData.push({...item, value: [item.value]})
              } else if (item.value.length){
                newData.push(item);
              }
            } else {
              newData.push(item);
            }
          }
        });
        newData.sort((a, b) => a.order - b.order);
      } catch (error) {
        console.log(error)
      }
      return newData;
    }
  },

  watch: {
    status: {
      immediate: false,
      handler() {
        this.getList();
      }
    }
  },

  created() {
    this.getList();
  },

  destroyed() {
    this.clearInterval();
  },

  methods: {
    handleDetail(key) {
      let res = ''
      switch(key) {
        case 'status':
          res = statusTexts[this.detail[key]].text;
          break;
        case 'complaint_type':
          res = complaintTypes[this.detail[key]];
          break;
        case 'subject':
          res = subjects[this.detail[key]];
          break;
        default:
          res = this.detail[key]
      }
      return res;
    },

    handleTabClick(tab, e) {
      console.log(tab, e);
    },

    getList() {
      window.lib.api.get({
        api: 'ec/c/workorder/query/right/complaint/list',
        data: this.listParams,
        success: res => {
          if (res.data) {
            this.tableData = res.data.data;
            this.totalCount = res.data.total_count
          }
        },
        error: err => {
          this.$message.error(err.msg);
        }
      })
    },

    getComplaintDetail(id) {
      window.lib.api.get({
        api: 'ec/c/workorder/right/complaint/get',
        data: { id },
        success: res => {
          this.detail = res.data;
          this.refuseReason = res.data.refuse_reason;
          if(this.timer) {
            this.clearInterval()
          }
          if (res.data.expired_time) {
            this.timeDiff = new Date(res.data.expired_time).getTime() - Date.now();
            this.timer = setInterval(() => {
              this.timeDiff -= 1000
              this.timeDiff <= 0 && this.clearInterval()
            }, 1000)
          }
          this.openDetailModal();
        },
        error: err => {
          this.$message.error(err.msg)
        }
      })
    },

    supplyEvidence() {
      if (!this.evidenceUrlList.length) {
        return this.$message.error('请上传补充证据')
      }
      window.lib.api.post({
        api: 'ec/c/workorder/supply/evidence',
        data: {
          id: this.detail.id,
          evidence_url_list: this.evidenceUrlList.join(',')
        },
        success: () => {
          this.$message.success('上传成功')
          this.evidenceUrlList = []
          this.closeSupplyModal()
          this.getList()
        },
        error: err => {
          this.$message.error(err.msg)
        }
      })
    },

    clearInterval() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },

    onCurrentPageChange(page) {
      this.currentPage = page;
      this.getList();
    },

    onEviImgChange(src) {
      this.evidenceUrlList.push(src);
    },

    onFileUploadError(e) {
      console.log(e);
      this.$message.error('文件上传失败')
    },

    onEviImgDelete(src) {
      this.evidenceUrlList = this.evidenceUrlList.filter(item => item !== src)
    },

    handleDetailClick(id) {
      this.getComplaintDetail(id);
    },

    onDetailConfirm() {
      this.closeDetailModal();
    },

    onDetailCancel() {
      this.closeDetailModal();
      this.openSupplyModal();
    },

    openDetailModal() {
      this.detailVisible = true;
    },

    closeDetailModal() {
      this.detailVisible = false;
    },

    openSupplyModal() {
      this.supplyVisible = true;
    },

    closeSupplyModal() {
      this.supplyVisible = false;
    }
  }
}
</script>

<style lang="less" scoped>
  .record {
    padding-top: 50px;
    .title {
      margin-bottom: 50px;
      line-height: 28px;
      text-align: center;
      color: #333;
      font-weight: 500;
      font-size: 20px;
    }
    .main {
      /deep/ .el-tabs__header {
        border-bottom: none;
      }
      /deep/ .el-tabs__nav {
        border-bottom: 1px solid #e4e7ed;
      }
      /deep/ .el-tabs__item {
        width: 180px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: #323233;
        cursor: pointer;
        &.is-active {
          background-color: #ff6000;
          color: #fff;
        }
      }

      .el-table {
        margin-top: 17px;
      }
      /deep/ .header-cell {
        background-color: #f7f7f7; 
      }
      .column {
        color: #888;
        font-weight: 500;
        font-size: 12px;
      }
      .success {
        color: #00a028;
      }
      .fail {
        color: #ff1414;
      }
      .item-detail {
        font-size: 12px;
        font-weight: 400;
        color: #306eed;
        cursor: pointer;
      }

      .pagination-container {
        margin-top: 35px;
        text-align: right;
      }
      /deep/ .el-pager {
        .number {
          &.active {
            background-color: #ff6000;
          }
        }
      }
    }

    .reason {
      display: flex;
      align-items: center;
      height: 40px;
      padding-left: 69px;
      margin-bottom: 20px;
      background-color: #fff5ed;
      border: 1px solid rgba(241, 146, 78, 1);
      border-left: none;
      border-right: none;
      .refuse-icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
      .text {
        color: #323233;
        font-size: 14px;
      }
    }
    .info-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding-left: 69px;
      padding-right: 29px;
      .label {
        margin-right: 16px;
        color: #333;
        font-weight: 400;
        font-size: 16px;
      }
      .val {
        flex: 1;
        color: #333;
        font-weight: 400;
        font-size: 16px;
      }
      .imgs {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .img-item {
          width: 90px;
          height: 90px;
          margin-right: 18px;
          margin-bottom: 10px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          object-fit: scale-down;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .type-info {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      color: #333;
      font-weight: 400;
      font-size: 16px;
      .label {
        margin-right: 10px;
      }
    }
  }
</style>

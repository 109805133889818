<template>
  <div v-if="visible" class="modal">
    <div class="mask" @click="close"></div>
    <div class="modal-box">
      <div v-if="title" class="title">{{title}}</div>
      <div class="content" :class="contentWithPadding ? 'with-padding' : ''">
        <slot></slot>
      </div>
      <div class="btns">
        <div v-if="showCancelBtn" class="btn btn-cancel" @click="onCancel">{{cancelText}}</div>
        <div class="btn btn-confirm" @click="onConfirm">{{confirmText}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: '确认'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    showCancelBtn: {
      type: Boolean,
      default: true
    },
    contentWithPadding: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    onConfirm() {
      this.$emit('confirm')
    },
    
    onCancel() {
      this.$emit('cancel')
    },

    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
  .modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .mask {
      position: fixed;
      z-index: 9;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .5);
    }
    .modal-box {
      box-sizing: border-box;
      position: absolute;
      z-index: 11;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 731px;
      // padding: 43px 29px 26px 69px;
      padding-top: 43px;
      padding-bottom: 26px;
      border-radius: 4px;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      .title {
        padding-left: 69px;
        padding-right: 29px;
        margin-bottom: 25px;
        color: #333;
        font-weight: 500;
        font-size: 20px;
      }
      .btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 64px;
        padding-left: 69px;
        padding-right: 29px;
        .btn {
          box-sizing: border-box;
          width: 167px;
          height: 44px;
          line-height: 44px;
          text-align: center;
          margin-right: 16px;
          border-radius: 2px;
          background-color: #fff;
          color: #333;
          font-weight: 400;
          font-size: 16px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          &.btn-cancel {
            border: 1px solid #dedede;
            &:hover {
              border: 1px solid #ff6000;
              color: #ff6000;
            }
          }
          &.btn-confirm {
            background-color: #ff6000;
            color: #fff;
            font-weight: 500;
            &:hover {
              background: rgba(255, 96, 0, 0.85);
            }
          }
        }
      }
      .content {
        max-height: 600px;
        overflow: auto;
        &.with-padding {
          padding-left: 69px;
          padding-right: 29px;
        }
      }
    }
  }
</style>
